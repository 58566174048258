import { Home } from "landing";
import { NextPage } from "next";
import { NextSeo } from "next-seo";

import { useSendCurrentLandingPageViewEvent } from "~utils/providers/analytics-provider";

const LandingPage: NextPage = () => {
	useSendCurrentLandingPageViewEvent();

	return (
		<>
			<NextSeo
				title="Mango GIS - Professional, simple online GIS maps"
				description="Create and securely share maps your way. The accessible and user-friendly alternative to Esri for smart spenders."
			/>
			<Home />
		</>
	);
};

export default LandingPage;
